import React from 'react'
import 'twin.macro'

export default function HeroDetail({data,page}){
    let finalData= data?.schema_json;  
    let content= {
        heading:"",
        sub_heading:"",
        description:"",
        img_src:"",
       ...finalData
  } 
    return (

        <div tw="bg-white pb-8 sm:pb-12 lg:pb-12 w-full">
        <div tw="lg:pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-32">
            <div tw="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24 ">
                <div tw="lg:mt-20 flex justify-center items-center">
                <div tw="lg:mt-6 sm:max-w-xl">
                    <div tw="text-4xl text-center lg:text-left font-extrabold text-gray-900 tracking-tight sm:text-5xl"  dangerouslySetInnerHTML={{ __html: content.heading }}></div>
                    <div tw="mt-6 text-xl text-center lg:text-left text-gray-500" dangerouslySetInnerHTML={{ __html: content.description }}></div>
                </div>
                </div>
            </div>

            <div tw="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div tw="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div tw="hidden sm:block">
                <div tw="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-96 lg:right-0 w-full"></div>
                <svg tw="absolute pt-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404" height="392" fill="none" viewBox="0 0 404 392">
                    <defs>
                    <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" tw="text-gray-200" fill="currentColor" />
                    </pattern>
                    </defs>
                    <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                </svg>
                </div>
                <div tw="relative pl-4 -mr-32 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-24">
                <img tw="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none" src={content.img_src} alt="" />
                </div>
            </div>
            </div>
        </div>
        </div>

    )
}
